import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import BG from "./error-box.png";
import Icon from "./error-icon.png";
import Close from "./close.png";

import "./index.scss";
import { useAnimateOpening } from "../betbar/dropdown";
import { motion } from "framer-motion";
import { getColor } from "../color";
import { formatCurrency } from "../../tool/money";
import { useAppSelector } from "../../store/hook";
import { getPlayer } from "../user/auth/reducer";
import { Player } from "../user/auth/type";
interface ErrorIProps extends ReturnType<typeof useAnimateOpening> {
}

const noException = [
    'already processed',
    'there is an error with game period',
    'there are still transactions in process',
];


const Error = ({
    handleOpen,
    active,
    containerRef,
    motionVariant
}: ErrorIProps) => {
    const { t, i18n } = useTranslation();
    const player = useAppSelector(getPlayer) as Player;
    const result = useAppSelector((state)=>state.result);

    const language = i18n.language;
    const handleClose = () => {
        handleOpen()
    }
    const { player_balance, player_name, player_currency } = player;

    const handleFormatCurrency = (amount: number): string =>
        formatCurrency(amount, player_currency, language);
    const [color, setColor] = useState();

    useEffect(() => {
        if(!active && result.status=="failed" && result.error && noException.every((except)=>!(result.error!.toLowerCase().includes(except.toLowerCase())))){
            handleOpen();
            return ()=>{
                // if(active)
                handleClose();
            }
        }
    }, [result.status, result.error, active])

    return (
        <motion.div
            initial="close"
            animate={active ? "open" : "close"}
            variants={motionVariant}
            // @ts-ignore
            ref={containerRef}
            className="result-error"
        >
            <div className="black"
                onClick={() => handleClose()}
            ></div>
            
            <img className="result-error-bg" alt="" src={BG} />
            <div className="result-error-title">
        <div className="title-bet"></div>
          
        <img className="title-close" alt="" src={Close} onClick={()=>handleClose()} />
      </div>
            <div className="multiplier">
        {/* <div className="multiplier-bg" style={{color: `red`}} /> */}
            <img className="result-error-bg" alt="" src={Icon} />
        <div className="multiplier-label">{"ERROR"}</div>
        <div className="multiplier-label-message">{result.error}</div>
      </div>
        </motion.div>
    );
};

export default Error;
